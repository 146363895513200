var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    { staticClass: "metaMenu" },
    [
      _vm._l(_vm.options, function (item) {
        return _c(
          "li",
          { class: _vm.itemClasses(item) },
          [
            _c("link-wrap", { attrs: { link: item.href } }, [
              _vm._v(_vm._s(item.title)),
            ]),
          ],
          1
        )
      }),
      _vm._v(" "),
      _c(
        "li",
        [
          _vm.isMainLanguage
            ? _c("LoginMenu", {
                attrs: { context: _vm.context, "meta-menu": "" },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }