































import {PlainObject} from '@labor-digital/helferlein/lib/Interfaces/PlainObject';
import {PageContext} from '@labor-digital/typo3-vue-framework/lib/Core/Context/PageContext';
import {RootLineElementInterface} from '@labor-digital/typo3-vue-framework/lib/Core/Interface/RootLineElementInterface';
import LanguageSwitchMixin from '../../../../../Mixin/LanguageSwitchMixin';
import {MenuService} from '../../../../../Service/MenuService';
import Icon from '../../../../Misc/Icon/Icon.vue';
import LinkWrap from '../../../../Misc/Link/LinkWrap/LinkWrap';
import LoginMenu from '../../../LoginMenu/LoginMenu.vue';

export default {
    name: 'MasterToggle',
    mixins: [LanguageSwitchMixin],
    components: {LoginMenu, LinkWrap, Icon},
    props: {
        context: null as PageContext,
        options: {
            type: Array,
            default: () => []
        }
    },
    methods: {
        isActive(item: RootLineElementInterface): boolean
        {
            return MenuService.isIdInRootLine(item.id);
        },
        itemClasses(item: RootLineElementInterface): PlainObject
        {
            return {
                'active': this.isActive(item)
            };
        }
    }
};
