
































import FindJobModule from '../FindJobModule/FindJobModule.vue';

export default {
    name: 'MobileFindJobModule',
    extends: FindJobModule
};
